import React from 'react'
import { formatValue } from 'react-currency-input-field'
import i18n from './i18n'

import { MailIcon } from '@heroicons/react/outline'
import {
  CompletionType,
  CommunicationType,
  FieldType,
  Frequency,
  AccountingIntegrationVendor,
  IntegrationsState,
  EmailRequestsAvailability,
  Currencies,
  AccessAction,
  Priority,
  Language,
  Role,
  PaymentStatus,
  PurchaseOrderStatus,
  TimeFormatType,
  QuoteStatus,
  ImportVendor,
  PipelineStatus,
} from '@type/common'
import { CryptoCurrencies } from './types/currency'

export const API_ADDRESS = import.meta.env.VITE_API_URL
export const SLACK_LOGIN = import.meta.env.VITE_SLACK_LOGIN
export const SLACK_INSTALL_LINK = import.meta.env.VITE_SLACK_INSTALL_LINK
export const PADDLE_VENDOR_ID = import.meta.env.VITE_PADDLE_VENDOR_ID
export const GTM_ID = import.meta.env.VITE_GTM_ID
export const GUIDES_LINK = import.meta.env.VITE_GUIDES_LINK
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

export const STRIPE_EASYPEASY_ANNUAL_PAYMENT_KEY = import.meta.env.VITE_STRIPE_EASYPEASY_ANNUAL_PAYMENT_KEY
export const STRIPE_EASYPEASY_MONTHLY_PAYMENT_KEY = import.meta.env.VITE_STRIPE_EASYPEASY_MONTHLY_PAYMENT_KEY

export const STRIPE_STARTER_ANNUAL_PAYMENT_KEY = import.meta.env.VITE_STRIPE_STARTER_ANNUAL_PAYMENT_KEY
export const STRIPE_STARTER_MONTHLY_PAYMENT_KEY = import.meta.env.VITE_STRIPE_STARTER_MONTHLY_PAYMENT_KEY

export const STRIPE_BASIC_ANNUAL_PAYMENT_KEY = import.meta.env.VITE_STRIPE_BASIC_ANNUAL_PAYMENT_KEY

export const STRIPE_PRO_ANNUAL_PAYMENT_KEY = import.meta.env.VITE_STRIPE_PRO_ANNUAL_PAYMENT_KEY

export const STRIPE_PILOT_PAYMENT_KEY = import.meta.env.VITE_STRIPE_PILOT_PAYMENT_KEY

export const UPDATE_SUBSCRIPTION_LINK = import.meta.env.VITE_UPDATE_SUBSCRIPTION_LINK
export const ENTERPRISE_REQUEST_LINK = import.meta.env.VITE_ENTERPRISE_REQUEST_LINK

export const STRIPE_CLIMATE_LINK = import.meta.env.VITE_STRIPE_CLIMATE_LINK
export const SEARCH_DISABLED_FOR = import.meta.env.VITE_SEARCH_DISABLED_FOR
export const XERO_PO_URL = import.meta.env.VITE_XERO_PO_URL
export const XERO_INVOICE_URL = import.meta.env.VITE_XERO_INVOICE_URL
export const XERO_BILL_URL = import.meta.env.VITE_XERO_BILL_URL
export const XERO_QUOTE_URL = import.meta.env.VITE_XERO_QUOTE_URL
export const XERO_CONNECTION_GUIDE = import.meta.env.VITE_XERO_CONNECTION_GUIDE
export const SLACK_CHANNEL_GUIDE = import.meta.env.VITE_SLACK_CHANNEL_GUIDE
export const SLACK_COMMUNITY_INVITE = import.meta.env.VITE_SLACK_COMMUNITY_INVITE
export const GUIDE_LINK_CREATE_WORKFLOW = import.meta.env.VITE_GUIDE_LINK_CREATE_WORKFLOW

export const QBO_PO_URL = import.meta.env.VITE_QBO_PO_URL
export const QBO_BILL_URL = import.meta.env.VITE_QBO_BILL_URL

export const HIDE_FIELDS_IN_SLACK_FOR = import.meta.env.VITE_HIDE_FIELDS_IN_SLACK_FOR
export const SHOW_I18N_FOR = import.meta.env.VITE_SHOW_I18N_FOR
export const SHOW_ORGS_FOR = import.meta.env.VITE_SHOW_ORGS_FOR
export const SHOW_CRYPTO_FOR = import.meta.env.VITE_SHOW_CRYPTO_FOR
export const SHOW_MAILBOX_FOR = import.meta.env.VITE_SHOW_MAILBOX_FOR
export const SHOW_PAYMENT_TYPE_SWITCHER_FOR = import.meta.env.VITE_SHOW_PAYMENT_TYPE_SWITCHER_FOR
export const SHOW_E_SIGNATURE_FOR = import.meta.env.VITE_SHOW_E_SIGNATURE_FOR
export const SHOW_NETSUITE_FOR = import.meta.env.VITE_SHOW_NETSUITE_FOR
export const SHOW_CUSTOMERS_FOR = import.meta.env.VITE_SHOW_CUSTOMERS_FOR
export const SHOW_AI_REPORTS_FOR = import.meta.env.VITE_SHOW_AI_REPORTS_FOR
export const CALL_LINK = import.meta.env.VITE_CALL_LINK
export const ENABLE_ONBOARDING_CALL = !!import.meta.env.VITE_CALL_LINK
export const YOUTUBE_PLAYLISTS = import.meta.env.VITE_YOUTUBE_PLAYLISTS
export const NETSUITE_CALL_LINK = import.meta.env.VITE_NETSUITE_CALL_LINK
export const INTERCOM_BILL_CASE_TOUR_ID = import.meta.env.VITE_INTERCOM_BILL_CASE_TOUR_ID

export const SHOW_MAINTENANCE_BANNER = Boolean(parseInt(import.meta.env.VITE_SHOW_MAINTENANCE_BANNER))

export const APPSTORE_LINK = import.meta.env.VITE_APPSTORE_LINK
export const GOOGLEPLAY_LINK = import.meta.env.VITE_GOOGLEPLAY_LINK

export const HOTJAR_ID = import.meta.env.VITE_HOTJAR_ID
export const HOTJAR_SV = import.meta.env.VITE_HOTJAR_SV

export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY

export const MAX_AMOUNT_VALUE = 999999999999.99
export const MAX_AMOUNT_VALUE_LABEL = formatValue({
  value: MAX_AMOUNT_VALUE.toString(),
  decimalSeparator: '.',
  groupSeparator: ' ',
  decimalScale: 2,
})

export { CompletionType, CommunicationType, FieldType as APPROVAL_REQUEST_FIELDS }
export const CompletionTypeLabels = {
  [CompletionType.all]: i18n.t('common:approveRules.all'),
  [CompletionType.any]: i18n.t('common:approveRules.any'),
  [CompletionType.empty]: i18n.t('common:approveRules.empty'),
}

export const comletionTypesOptions = [
  {
    value: CompletionType.all,
    label: CompletionTypeLabels[CompletionType.all],
  },
  {
    value: CompletionType.any,
    label: CompletionTypeLabels[CompletionType.any],
  },
]

export const communicationTypes = {
  [CommunicationType.email]: {
    Icon: MailIcon,
    label: 'Email',
    key: CommunicationType.email,
  },
  [CommunicationType.slack]: {
    Icon: ({ className }: { className: string }) => <img src="/slack.png" className={className} />,
    label: 'Slack',
    key: CommunicationType.slack,
  },
  [CommunicationType.microsoft]: {
    Icon: ({ className }: { className: string }) => <img src="/teams.png" className={className} />,
    label: 'Teams',
    key: CommunicationType.microsoft,
  },
}

export const importVendors = {
  [ImportVendor.slack]: {
    Icon: ({ className }: { className: string }) => <img alt="Slack" src="/vendors/slack.svg" className={className} />,
    label: 'Slack',
    key: ImportVendor.slack,
  },
  [ImportVendor.microsoft]: {
    Icon: ({ className }: { className: string }) => <img alt="Teams" src="/vendors/teams.svg" className={className} />,
    label: 'Teams',
    key: ImportVendor.microsoft,
  },
  [ImportVendor.bamboohr]: {
    Icon: ({ className }: { className: string }) => (
      <img alt="Bamboo" src="/vendors/bamboohr.svg" className={className} />
    ),
    label: 'Bamboo HR',
    key: ImportVendor.bamboohr,
  },
}

export const emailRequestsAvailability = {
  [EmailRequestsAvailability.private]: {
    label: 'Yes',
    key: EmailRequestsAvailability.private,
  },
  [EmailRequestsAvailability.public]: {
    label: 'No',
    key: EmailRequestsAvailability.public,
  },
}

export const SLACK_BOT = {
  query: 'slack_installed',
  key: 'slack',
  name: 'Slack',
  img: '/slack.png',
  link: SLACK_INSTALL_LINK,
  login: SLACK_LOGIN,
}

export const FrequencyItems = {
  [Frequency.daily]: i18n.t('common:reminderFrequency.daily'),
}

export const accountingVendors: {
  [key in AccountingIntegrationVendor]: {
    name: string
    requestTitle?: string
    abbr?: string
    key: AccountingIntegrationVendor
    iconSrc?: string
    connectIconSrc?: string
    fullIconSrc?: string
  }
} = {
  [AccountingIntegrationVendor.xero]: {
    name: 'Xero',
    key: AccountingIntegrationVendor.xero,
    fullIconSrc: '/vendors/full/xero.svg',
    iconSrc: '/vendors/xero.svg',
  },
  [AccountingIntegrationVendor.quickbooks]: {
    name: 'QuickBooks',
    key: AccountingIntegrationVendor.quickbooks,
    iconSrc: '/vendors/quickbooks.svg',
    fullIconSrc: '/vendors/quickbooks.svg',
  },
  [AccountingIntegrationVendor.softledger]: {
    name: 'QuickBooks',
    key: AccountingIntegrationVendor.softledger,
    iconSrc: '/vendors/softledger-integration.svg',
    fullIconSrc: '/vendors/full/softledger.svg',
  },
}

export const integrationConnectionStatus = {
  [IntegrationsState.connected]: 'Connected',
  [IntegrationsState.not_connected]: 'Not connected',
}

export const currencyOptions = Object.values(Currencies).map((value) => ({ label: value, value }))
export const cryptoCurrencyOptions = Object.values(CryptoCurrencies).map((value) => ({ label: value, value }))
export const priorityCurrencies = ['USD', 'EUR', 'GBP', 'CAD', 'AUD', 'NZD', 'SGD']

export const AccessActions = {
  [AccessAction.grant]: 'Grant',
  [AccessAction.restrict]: 'Restrict',
}

export const priorities = {
  [Priority.low]: {
    label: i18n.t('common:priority.low'),
    value: Priority.low,
  },
  [Priority.medium]: {
    label: i18n.t('common:priority.medium'),
    value: Priority.medium,
  },
  [Priority.high]: {
    label: i18n.t('common:priority.high'),
    value: Priority.high,
  },
  [Priority.critical]: {
    label: i18n.t('common:priority.critical'),
    value: Priority.critical,
  },
}

export const priorityOptions = Object.values(priorities)

export const paymentStatuses: Record<
  PaymentStatus,
  { label: string; value: PaymentStatus; color?: string; borderColor?: string; bgColor?: string }
> = {
  [PaymentStatus.paid]: {
    label: i18n.t('common:paymentStatus.paid'),
    value: PaymentStatus.paid,
    color: 'text-emerald-700',
  },
  [PaymentStatus.unpaid]: {
    label: i18n.t('common:paymentStatus.unpaid'),
    value: PaymentStatus.unpaid,
    color: 'text-natural-700',
  },
}

export const purchaseOrderStatuses: Record<
  PurchaseOrderStatus,
  { label: string; value: PurchaseOrderStatus; textColor?: string; borderColor?: string; bgColor?: string }
> = {
  [PurchaseOrderStatus.approved]: {
    label: i18n.t('common:purchaseOrderStatus.approved'),
    value: PurchaseOrderStatus.approved,
    textColor: 'text-emerald-700',
    borderColor: 'border-emerald-200',
    bgColor: 'bg-emerald-50',
  },
  [PurchaseOrderStatus.billed]: {
    label: i18n.t('common:purchaseOrderStatus.billed'),
    value: PurchaseOrderStatus.billed,
    textColor: 'text-sky-700',
    borderColor: 'border-sky-200',
    bgColor: 'bg-sky-50',
  },
  [PurchaseOrderStatus.canceled]: {
    label: i18n.t('common:purchaseOrderStatus.canceled'),
    value: PurchaseOrderStatus.canceled,
    borderColor: 'border-gray-200',
    bgColor: 'bg-gray-50',
    textColor: 'text-gray-700',
  },
  [PurchaseOrderStatus.draft]: {
    label: i18n.t('common:purchaseOrderStatus.draft'),
    value: PurchaseOrderStatus.draft,
    borderColor: 'border-indigo-100',
    bgColor: 'bg-indigo-50',
    textColor: 'text-indigo-800',
  },
  [PurchaseOrderStatus.closed]: {
    label: i18n.t('common:purchaseOrderStatus.closed'),
    value: PurchaseOrderStatus.closed,
    borderColor: 'border-indigo-100',
    textColor: 'text-indigo-800',
    bgColor: 'bg-gray-50',
  },
}

export const quoteStatuses: Record<
  QuoteStatus,
  { label: string; value: QuoteStatus; textColor?: string; borderColor?: string; bgColor?: string }
> = {
  [QuoteStatus.approved]: {
    label: i18n.t('common:quoteStatus.approved'),
    value: QuoteStatus.approved,
    textColor: 'text-emerald-700',
    borderColor: 'border-emerald-200',
    bgColor: 'bg-emerald-50',
  },
  [QuoteStatus.invoiced]: {
    label: i18n.t('common:quoteStatus.invoiced'),
    value: QuoteStatus.invoiced,
    textColor: 'text-sky-700',
    borderColor: 'border-sky-200',
    bgColor: 'bg-sky-50',
  },
  [QuoteStatus.canceled]: {
    label: i18n.t('common:quoteStatus.canceled'),
    value: QuoteStatus.canceled,
    borderColor: 'border-gray-200',
    bgColor: 'bg-gray-50',
    textColor: 'text-gray-700',
  },
  [QuoteStatus.draft]: {
    label: i18n.t('common:quoteStatus.draft'),
    value: QuoteStatus.draft,
    borderColor: 'border-indigo-100',
    bgColor: 'bg-indigo-50',
    textColor: 'text-indigo-800',
  },
  [QuoteStatus.closed]: {
    label: i18n.t('common:quoteStatus.closed'),
    value: QuoteStatus.closed,
    borderColor: 'border-indigo-100',
    textColor: 'text-indigo-800',
    bgColor: 'bg-gray-50',
  },
}

export const paymentStatusesOptions = Object.values(paymentStatuses)

export const pipelineStatuses: Record<
  PipelineStatus,
  { label: string; value: PipelineStatus; textColor?: string; borderColor?: string; bgColor?: string }
> = {
  [PipelineStatus.active]: {
    label: i18n.t('common:pipelineStatus.active'),
    value: PipelineStatus.active,
    textColor: 'text-emerald-700',
    borderColor: 'border-emerald-200',
    bgColor: 'bg-emerald-50',
  },
  [PipelineStatus.inactive]: {
    label: i18n.t('common:pipelineStatus.inactive'),
    value: PipelineStatus.inactive,
    textColor: 'text-neutral-700',
    borderColor: 'border-gray-200',
    bgColor: 'bg-gray-50',
  },
}

export const languageOptions = Object.values(Language).map((value) => ({ label: value, value }))

export const userRoleLabel: Record<string, string> = {
  [Role.admin]: i18n.t('common:userRoles.admin'),
  [Role.requestor]: i18n.t('common:userRoles.member'),
  notInvited: i18n.t('common:userRoles.notInvited'),
  invited: i18n.t('common:userRoles.invited'),
  requestingInvite: i18n.t('common:userRoles.requestingInvite'),
}

export const timeFormat = {
  [TimeFormatType.date]: 'yyyy-MM-dd',
  [TimeFormatType.datetime]: 'yyyy-MM-dd hh:mm a z',
}

export const ACCOUNTING_INTEGRATIONS = [
  AccountingIntegrationVendor.quickbooks,
  AccountingIntegrationVendor.xero,
  AccountingIntegrationVendor.softledger,
]

export const listOfCountries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
]
