import { validate } from 'uuid'
import { AxiosError } from 'axios'
import { format, utcToZonedTime } from 'date-fns-tz'
import { toast } from '@src/helpers/toaster'
import { FormikErrors } from 'formik'
import { isValid } from 'date-fns'
import * as Sentry from '@sentry/react'

import i18n from '@src/i18n'
import React from 'react'

export const checkIsLink = (link: string) => {
  const regex = /(https?|slack:\/\/([-\w\.]+)+(:\d+)?(\/([\w\/_\.]*(\?\S+)?)?)?)/gi

  return regex.test(link)
}

export const checkIsFile = (fileLink: string) => {
  if (fileLink.startsWith(window.location.origin)) {
    const uuid = fileLink?.split('/')?.pop()
    if (uuid) {
      return validate(uuid)
    }
  }

  return false
}

export const str2num = (str?: string) => {
  if (typeof str !== 'string' || !str) {
    return 0
  }
  const [i, f] = str.split('.')
  const val = {
    integer: i ? i.replace(/\s/g, '') : 0,
    fraction: f || 0,
  }

  return +`${val.integer}.${val.fraction}`
}
export const getCurrencySymbol = (currency: string) => {
  return (
    new Intl.NumberFormat('en-US', { style: 'currency', currency })
      ?.formatToParts(1)
      ?.find((x) => x.type === 'currency')?.value || ''
  )
}
export const val2Amount = (value: string | number, currency?: string) => {
  let symbol = ''
  if (currency) {
    symbol = getCurrencySymbol(currency)
  }
  return (
    symbol +
    // ' ' +
    (+value / 100).toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(',', '.')
  )
}

export const sanitizeInput = (string: string): string => {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
  }
  const reg = /[&<>"'/]/gi
  return string.replace(reg, (match: string) => map[match as keyof typeof map])
}

export const getCeilValue = (value: number) => {
  return Math.round(value * 100) / 100
}

export const formatDate = (date?: Date | string | null, withTime = true, withTimezone = false): string => {
  try {
    if (!date) {
      return ''
    }
    if (!withTime) {
      return format(typeof date === 'string' ? new Date(date) : date, 'yyyy-MM-dd')
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const rawDate = utcToZonedTime(date, timezone, {
      timeZone: 'UTC',
    })

    return format(rawDate, `yyyy-MM-dd hh:mm a` + (withTimezone ? ` zzz` : ''))
  } catch {
    return ''
  }
}

export const formatTime = (dateTime?: string | null): string => {
  try {
    if (!dateTime) return ''

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const isTimeOnly = !isValid(new Date(dateTime))
    const formattedDate = isTimeOnly
      ? utcToZonedTime(`${format(new Date(), 'yyyy-MM-dd')}T${dateTime}`, timezone, { timeZone: 'UTC' })
      : utcToZonedTime(dateTime, timezone, { timeZone: 'UTC' })

    return format(formattedDate, 'hh:mm a')
  } catch {
    return ''
  }
}

export const getNestedError = (err?: unknown): string | null => {
  if (!err) {
    return null
  }
  const isArray = Array.isArray(err)

  const isString = typeof err === 'string'
  const isObject = typeof err === 'object'

  if (isArray) {
    const errorItem = err.find((errItem) => !!getNestedError(errItem))
    return getNestedError(errorItem)
  }
  if (isObject) {
    return getNestedError(Object.values(err || {}))
  }
  if (isString) {
    return err
  }

  return null
}

export const requestErrorsHandler = (err: unknown, errorText?: string) => {
  if (!err) {
    return
  }
  if (import.meta.env.VITE_LOGGER === '1') {
    console.log(err, errorText)
  }

  let errorMessage: string | null = ''
  if ((err as Error)?.message && !(err as AxiosError)?.response) {
    errorMessage = (err as Error).message
  }
  const res = (err as AxiosError)?.response?.data as Record<string, string>
  if (res?.detail) {
    errorMessage = res?.detail
  } else {
    if (typeof res === 'object') {
      const errors: string[] | unknown[] = Object.values(res || {})
      errorMessage = getNestedError(errors)
    }
  }
  const captured = Sentry.captureException(err)

  toast.error(
    <>
      {errorMessage || errorText || (i18n.t('common:toast.somethingWentWrong') as string)}
      {!errorMessage && !errorText && (
        <>
          <br />
          <br />
          Send this ID to our support: {captured}
        </>
      )}
    </>,
  )
  return errorMessage || errorText || i18n.t('common:toast.somethingWentWrong')
}

export const parseJWT = <T = Record<string, unknown>,>(token: string): T => {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
  } catch (e) {
    return {} as T
  }
}

export const getInitials = (name?: string | undefined) => {
  if (!name) return 'AP'
  const ignoredWords = ['the', 'a', 'and', '&', 'of']
  const words = name.split(' ').filter((word: string) => {
    return !ignoredWords.includes(word.toLowerCase())
  })

  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase()
  } else if (words.length >= 2) {
    return (words?.at(0)?.at(0) || '' + words?.at(1)?.at(0) || '').toUpperCase()
  }
  return 'AP'
}

export const copyToClipboard = async (text: string) => {
  if (!text || typeof text !== 'string') return
  try {
    await navigator.clipboard.writeText(text)
    toast.success(i18n.t('settings:verification.typeCode.clipboardCopySuccess') as string)
  } catch (err) {
    toast.error(i18n.t('settings:verification.errors.generalError') as string)
  }
}

export const getFirstError = (obj: FormikErrors<unknown>): null | string => {
  const prop = Object.values(obj)?.[0]
  if (!prop) {
    return null
  }
  return typeof prop === 'string' ? prop : getFirstError(prop)
}
